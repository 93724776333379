import { FIRST_LOAN_PERCENT } from "constants/onlineLoan";
import BaseButton from "form-submodule/atoms/BaseButton";
import ModalPortal from "form-submodule/atoms/ModalPortal";
import { backReqV2, formatMoney } from "helpers";
import AvatarGroup from "img/avatar_group.png";
import { ReactComponent as CheckCircleIcon } from "img/check-circle.svg";

import { ReactComponent as InfoIcon } from "img/info.svg";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import appStore from "store/app";
import getYaId from "utils/getYaId";
import { calculateMonthlyPayment } from "utils/onlineLoanMonthlyPayment";
import { yandexMetrikaReachGoal } from "utils/yandex-metrika";
import s from "./Calculator.module.scss";
import CalculatorAction from "./CalculatorAction";
import Sliders from "./Sliders";

const Calculator = observer(({ isMain = false, referCode }) => {
	const [monthlyPayment, setMonthlyPayment] = useState(0);
	const [isTouched, setIsTouched] = useState(false);
	const [sendToMetrika, setSendToMetrika] = useState(false);
	const [isSigned, setIsSigned] = useState(false);
	const [isMoreModalOpen, setIsMoreModalOpen] = useState(false);

	const { loanTerm, desiredAmount, id } = appStore.loanDetail;
	useEffect(() => {
		setMonthlyPayment(
			calculateMonthlyPayment(desiredAmount, loanTerm, FIRST_LOAN_PERCENT),
		);
	}, [desiredAmount, loanTerm]);

	useEffect(() => {
		async function fetch() {
			const loanId = localStorage.getItem("loanId");
			if (!loanId) return;
			const response = await backReqV2("detail", { id: +loanId });
			if (
				response?.data?.id === loanId &&
				response?.data?.electronicInteractionAgreement
			) {
				setIsSigned(true);
				appStore.setLoanDetail({
					...appStore.loanDetail,
					id: response.data.id,
				});
			}
		}

		fetch();
	}, []);

	const onContinue = async () => {
		if (isMain && isTouched && id) {
			const x = await backReqV2("params", {
				id,
				desiredAmount,
				loanTerm,
				personalDataAgreement: true, // заглушка для обязательного поля. Сейчас скрыто на фронте, но есть на бэке
				loanPurpose: "Прочие личные нужды",
				yandexId: getYaId(),
			});
			if (x?.errors) {
				return;
			}
		}
		if (isMain && appStore.isFirstSend && !sendToMetrika) {
			yandexMetrikaReachGoal("online_step1");
			setSendToMetrika(true);
		}
		appStore.setIsLoanModalVisible(true);
	};

	const handleMoreClick = () => {
		setIsMoreModalOpen(true);
	};

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		if (isSigned) {
			onContinue();
		}
	}, [isSigned]);

	return (
		<div
			className={`${s.calculator__wrapper} ${!isMain ? s.calculator__wrapper_step : ""}`}
		>
			<Sliders isMain={isMain} isEqual={false} setIsTouched={setIsTouched} />

			<div className={s.calculator__resultCard}>
				<div className={s.calculator__resultWrapper}>
					<div className={s.calculator__result}>
						<p className={s.calculator__resultWrapperTitle}>платёж от:</p>
						<h4 className={s.calculator__money}>
							{formatMoney(monthlyPayment)}
							<span className={s.calculator__currency}>₽</span>
						</h4>
					</div>
				</div>
				<button className={s.calculator__more} onClick={handleMoreClick} type="button">
					Подробнее <InfoIcon className={s.calculator__moreIcon} />
				</button>
			</div>
			{isMain && (
				<CalculatorAction
					onContinue={onContinue}
					setIsSigned={setIsSigned}
					referCode={referCode}
				/>
			)}

			<ModalPortal
				showModal={isMoreModalOpen}
				closeModal={() => setIsMoreModalOpen(false)}
			>
				<div className={s.moreModal}>
					<img src={AvatarGroup} alt="avatar group" />
					<h4>Ежемесячный платёж</h4>
					<p>
						Мы заботимся о клиентах и не просим погашать займ крупными равными
						частями, только процент
					</p>
					<div className={s.moreModalCard}>
						<div>
							<CheckCircleIcon />
							Ежемесячно погашайте только процент, а основную сумму в любое
							удобное время до окончания срока договора
						</div>
						<div>
							<CheckCircleIcon />
							Напоминаем о платежах в личном кабинете
						</div>
						<div>
							<CheckCircleIcon />
							Не начисляем пени и штрафы за досрочное закрытие
						</div>
					</div>
					<hr />
					<BaseButton
						text="Всё понятно"
						onClick={() => setIsMoreModalOpen(false)}
					/>
				</div>
			</ModalPortal>
		</div>
	);
});

export default Calculator;
