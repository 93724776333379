import { isDev } from "config";
import { loanStepsArray } from "constants/onlineLoan";
import ErrorContainer from "form-submodule/molecules/ErrorContainer";
import StepChangeButtons from "form-submodule/molecules/StepChangeButtons";
import { nextStepHandler } from "form-submodule/steps/helpers";
import { backReq, backReqV2 } from "helpers";
import useErrorContainer from "hooks/useErrorContainer";
import useRefreshOnlineLoanDetail from "hooks/useRefreshOnlineLoan";
import useScript from "hooks/useScript";
import { observer } from "mobx-react";
import React, { useImperativeHandle, useState } from "react";
import appStore from "store/app";
import elementsStore from "store/elementsStore";
import draftFromState from "utils/draftFromState";
import { errorsParser } from "utils/errorsParser";
import { maritalStatusTemplate } from "utils/templates/martialStatusTemplate";
import { validateAddress, validateDate } from "utils/validators";
import s from "./OnlineLoanPersonal.module.scss";
import PassportForm from "./PassportForm";
import PersonalForm from "./PersonalForm";

window.juicyLabConfig = {
	nextButton: "your-next-button-id",
	stopPingButton: "your-ping-button-id",
	completeButton: "nextStepButton",
	apiKey: isDev ? "3NuhHBVy6HcCrYsQgqzXMSgV" : "SlEkfa01UDsArciQcjA77Zcg",
};
const RegistrationPersonal = React.forwardRef((props, ref) => {
	useScript("https://score.juicyscore.com/static/js.js");

	const [personalForm, setPersonalForm] = useState({});
	const [passportForm, setPassportForm] = useState(null);

	const [
		isPersonalDataError,
		setIsPersonalDataError,
		personalDataContainerRef,
	] = useErrorContainer();
	const [
		isPassportDataError,
		setIsPassportDataError,
		passportDataContainerRef,
	] = useErrorContainer();

	useRefreshOnlineLoanDetail(insertData);

	const setGender = (g) => {
		if (g === "none" || !g) {
			return "";
		}
		return g === "m" ? "Мужской" : "Женский";
	};

	async function insertData() {
		const loan = appStore?.loanDetail || {};
		const draft = draftFromState({ ...loan });
		console.log({ draft, loan });

		setPassportForm({
			...loan,
			passportSeries: draft.passportSeries || loan.passportSeries,
			passportNumber: draft.passportNumber || loan.passportNumber,
			passportDate: draft.passportDate || loan.passportDate,
			passportIssuedBy:
				draft.passportIssuedBy?.value ||
				draft.passportIssuedBy ||
				loan.passportIssuedBy,
			registrationDate: draft.registrationDate || loan.registrationDate,
			divisionCode: draft.divisionCode || loan.divisionCode,
			registration: draft.registration || loan.registration || "",
			registrationIsActual: draft.registrationIsActual
				? JSON.parse(draft.registrationIsActual)
				: loan.registrationIsActual,
			actualRegistration:
				draft.actualRegistration?.value ||
				draft.actualRegistration ||
				loan.actualRegistration ||
				"",
			maritalStatus:
				draft.maritalStatus ||
				maritalStatusTemplate.find((c) => c.value === loan.maritalStatus)
					?.label ||
				"",
			registrationFlat:
				JSON.parse(sessionStorage.getItem("registrationFlat")) || "",
			registrationFlatIsAbsent:
				JSON.parse(sessionStorage.getItem("registrationFlatIsAbsent")) || false,
			actualRegistrationFlat:
				JSON.parse(sessionStorage.getItem("actualRegistrationFlat")) || "",
			actualRegistrationFlatIsAbsent:
				JSON.parse(sessionStorage.getItem("actualRegistrationFlatIsAbsent")) ||
				false,
		});

		const gender = draft.gender || setGender(loan.gender);
		setPersonalForm({
			...loan,
			gender,
			isNameChanged: false,
			surname: draft.surname || loan.surname,
			name: draft.name || loan.name,
			patronymic: draft.patronymic || loan.patronymic,
			prevSurname: draft.prevSurname || loan.prevSurname,
			prevName: draft.prevName || loan.prevName,
			prevPatronymic: draft.prevPatronymic || loan.prevPatronymic,
			birthDate: draft.birthDate || loan.birthDate,
			birthPlace: draft.birthPlace || loan.birthPlace,
			snils: draft.snils || loan.snils,
			nameChanged: draft.nameChanged
				? JSON.parse(draft.nameChanged)
				: loan.nameChanged || false,
		});
	}

	async function validate() {
		const {
			surname,
			name,
			patronymic,
			prevSurname,
			prevName,
			prevPatronymic,
			snils,
			nameChanged,
			gender,
			birthDate,
			birthPlace,
		} = personalForm;
		let personalFieldsArray = [
			surname?.value || surname,
			name?.value || name,
			patronymic?.value || patronymic,
			gender,
			birthDate,
			birthPlace?.value || birthPlace,
		];
		if (nameChanged) {
			personalFieldsArray = [
				...personalFieldsArray,
				prevName?.value || prevName,
				prevSurname?.value || prevSurname,
				prevPatronymic?.prevPatronymic || prevPatronymic,
			];
		}
		console.log(personalFieldsArray);

		if (
			personalFieldsArray.some(
				(field) => !(field?.value ? Boolean(field.value) : field?.trim()),
			)
		) {
			setIsPersonalDataError(true);
			elementsStore.showSnackbar(errorsParser("Заполните все поля"));
			return false;
		}
		if (!validateDate(birthDate)) {
			setIsPersonalDataError(true);
			elementsStore.showSnackbar(errorsParser("Заполните дату корректно"));
			return false;
		}
		if (!!snils && snils.length !== 14) {
			setIsPersonalDataError(true);
			elementsStore.showSnackbar(
				errorsParser("Введите корректный номер СНИЛС"),
			);
			return false;
		}
		const {
			passportSeries,
			passportNumber,
			passportIssuedBy,
			passportDate,
			registrationDate,
			divisionCode,
			registration,
			registrationIsActual,
			actualRegistration,
			maritalStatus,
			registrationFlat,
			registrationFlatIsAbsent,
			actualRegistrationFlat,
			actualRegistrationFlatIsAbsent,
		} = passportForm;

		const passportFieldsArray = [
			passportSeries,
			passportNumber,
			registrationDate,
			passportIssuedBy,
			passportDate,
			divisionCode,
			maritalStatus,
		];

		console.log(passportFieldsArray);

		if (
			passportFieldsArray.some(
				(field) => !(field?.value ? Boolean(field.value) : field?.trim()),
			)
		) {
			setIsPassportDataError(true);
			elementsStore.showSnackbar(
				errorsParser("Пожалуйста, укажите все данные"),
			);
			return false;
		}
		if (
			passportSeries.toString().length < 4 ||
			passportNumber.toString().length < 6 ||
			divisionCode.length < 7
		) {
			setIsPassportDataError(true);
			elementsStore.showSnackbar(
				errorsParser("Необходимо заполнить паспортные данные"),
			);
			return false;
		}
		if (!validateDate(passportDate)) {
			setIsPassportDataError(true);
			elementsStore.showSnackbar(errorsParser("Заполните дату корректно"));
			return false;
		}
		if (!validateDate(registrationDate)) {
			setIsPassportDataError(true);
			elementsStore.showSnackbar(errorsParser("Заполните дату корректно"));
			return false;
		}
		const registrationString = `${registration?.value || registration}, кв ${registrationFlatIsAbsent ? "1" : registrationFlat}`;
		console.log({ registrationString });
		if (!(await validateAddress(registrationString, true))) {
			setIsPassportDataError(true);
			elementsStore.showSnackbar(errorsParser("Заполните адрес регистрации"));
			return false;
		}
		if (!(await validateAddress(registrationString))) {
			setIsPassportDataError(true);
			elementsStore.showSnackbar(
				errorsParser("Заполните поле «Квартира» или нажмите “Нет квартиры”"),
			);
			return false;
		}
		if (!registrationIsActual) {
			const actualRegistrationString = `${actualRegistration?.value || actualRegistration}, кв ${actualRegistrationFlatIsAbsent ? "1" : actualRegistrationFlat}`;
			console.log({ actualRegistrationString });
			if (!(await validateAddress(actualRegistrationString, true))) {
				setIsPassportDataError(true);
				elementsStore.showSnackbar(
					errorsParser("Заполните адрес фактического проживания"),
				);
				return false;
			}
			if (!(await validateAddress(actualRegistrationString))) {
				setIsPassportDataError(true);
				elementsStore.showSnackbar(
					errorsParser("Заполните поле «Квартира» или нажмите “Нет квартиры”"),
				);
				return false;
			}
		}

		return true;
	}

	async function sendData() {
		try {
			const transactionId = localStorage.getItem("transactionId");
			const id = appStore?.loanDetail?.id;
			if (!id) return { errors: "no id" };
			sendJuicyScore(id);
			const response = await backReqV2("client_data", {
				...(transactionId && { bankiTransactionId: transactionId }),
				cityStr: passportForm.registrationIsActual
					? passportForm.city
					: passportForm.actualCity,
				id,
				surname: personalForm.surname?.value || personalForm.surname,
				name: personalForm.name?.value || personalForm.name,
				patronymic: personalForm.patronymic?.value || personalForm.patronymic,
				nameChanged: personalForm.nameChanged,
				prevSurname: personalForm.nameChanged
					? personalForm.prevSurname?.value || personalForm.prevSurname
					: "",
				prevName: personalForm.nameChanged
					? personalForm.prevName?.value || personalForm.prevName
					: "",
				prevPatronymic: personalForm.nameChanged
					? personalForm.prevPatronymic?.value || personalForm.prevPatronymic
					: "",
				gender: personalForm.gender === "Мужской" ? "m" : "f",
				birthDate: personalForm.birthDate,
				birthPlace: personalForm.birthPlace?.value || personalForm.birthPlace,
				snils: personalForm.snils,

				isMilitaryTicket: false,
				categoryMilitaryTicket: "",
				passportSeries: passportForm.passportSeries,
				passportNumber: passportForm.passportNumber,
				passportIssuedBy:
					passportForm.passportIssuedBy?.value || passportForm.passportIssuedBy,
				registrationDate: passportForm.registrationDate,
				passportDate: passportForm.passportDate,
				divisionCode: passportForm.divisionCode,
				registration: `${passportForm.registration?.value || passportForm.registration}, кв ${passportForm.registrationFlatIsAbsent ? "1" : passportForm.registrationFlat}`,
				registrationIsActual: passportForm.registrationIsActual,
				criminal: false,
				prosecuted: false,
				actualRegistration: `${passportForm.actualRegistration?.value || passportForm.actualRegistration}, кв ${passportForm.actualRegistrationFlatIsAbsent ? "1" : passportForm.actualRegistrationFlat}`,
				isHaveInternationalPassport: false,
				maritalStatus: maritalStatusTemplate.find(
					(c) => c.label === passportForm.maritalStatus,
				).value,
			});
			if (response.errors) {
				return response;
			}
			return response;
		} catch (e) {
			return { errors: e.message };
		}
	}

	const sendJuicyScore = async (id) => {
		const sessionId = (await window.juicyScoreApi.getSessionId()) ?? null;
		if (!sessionId) return;
		try {
			return await backReq("juicy_score_session", {
				id,
				sessionId,
			});
		} catch (e) {
			console.warn(e);
		}
	};

	useImperativeHandle(ref, () => ({
		validate,
		sendData,
		current: loanStepsArray[1],
	}));
	return (
		<div>
			<ErrorContainer
				innerRef={personalDataContainerRef}
				hasError={isPersonalDataError}
			>
				<div>
					<div className={s.titleWrapper}>
						<p className={s.title}>Основные данные</p>
					</div>
					{personalForm && (
						<PersonalForm
							personalForm={personalForm}
							setPersonalForm={setPersonalForm}
						/>
					)}
				</div>
			</ErrorContainer>

			<ErrorContainer
				innerRef={passportDataContainerRef}
				hasError={isPassportDataError}
			>
				<div>
					<div
						className={[s.titleWrapper, s["titleWrapper--withMessage"]].join(
							" ",
						)}
					>
						<p className={s.title}>Паспортные данные</p>
					</div>
					{passportForm && (
						<PassportForm
							passportForm={passportForm}
							setPassportForm={setPassportForm}
						/>
					)}
				</div>
			</ErrorContainer>
			<div>
				<StepChangeButtons
					specialDisabled={passportForm?.publicPerson}
					callback={nextStepHandler(validate, sendData, loanStepsArray[1])}
				/>
			</div>
		</div>
	);
});

export default observer(RegistrationPersonal);
