import React from "react";

import s from "./OnlineLoanPersonal.module.scss";

import BaseCheckbox from "form-submodule/atoms/BaseCheckbox";
import BaseInput from "form-submodule/atoms/BaseInput";
import BaseMessage from "form-submodule/atoms/BaseMessage";
import DadataInput from "form-submodule/atoms/DadataInput";
import DateInput from "form-submodule/atoms/DateInput";
import NativeSelect from "form-submodule/molecules/NativeSelect";

const genderTemplate = ["Мужской", "Женский"];
const PersonalForm = ({ personalForm, setPersonalForm, disabled = false }) => {
	const {
		surname = "",
		name = "",
		patronymic = "",
		prevSurname = "",
		prevName = "",
		prevPatronymic = "",
		nameChanged = false,
		gender = "",
		birthDate = "",
		birthPlace = "",
		snils = "",
	} = personalForm;

	const handleNameChanged = () => {
		sessionStorage.setItem("nameChanged", !nameChanged);
		setPersonalForm({ ...personalForm, nameChanged: !nameChanged });
	};

	return (
		<form
			autoComplete={"off"}
			className={[s.inputsGroup, s.inputsGroup__main].join(" ")}
		>
			<div>
				<DadataInput
					type="surname"
					placeholder="Фамилия *"
					defaultValue={surname.value || surname}
					sessionKey="surname"
					value={(v) =>
						setPersonalForm({ ...personalForm, surname: v.value || "" })
					}
					disabled={disabled}
					onlyLetters
				/>
			</div>
			<div>
				<DadataInput
					type="name"
					placeholder="Имя *"
					defaultValue={name.value || name}
					sessionKey="name"
					value={(v) =>
						setPersonalForm({ ...personalForm, name: v.value || "" })
					}
					disabled={disabled}
					onlyLetters
				/>
			</div>
			<div>
				<DadataInput
					type="patronymic"
					placeholder="Отчество *"
					defaultValue={patronymic.value || patronymic}
					sessionKey="patronymic"
					value={(v) =>
						setPersonalForm({ ...personalForm, patronymic: v.value || "" })
					}
					disabled={disabled}
					onlyLetters
				/>
			</div>

			<div className={`${s.row} ${s.reduceGap}`}>
				<div className={s.checkboxWrapper}>
					<BaseCheckbox
						inversion
						value={!nameChanged}
						onChange={handleNameChanged}
						disabled={disabled}
					>
						ФИО не менялись
					</BaseCheckbox>
					{nameChanged && (
						<>
							<BaseMessage
								type="warning"
								shadow={true}
								centered={false}
								close={false}
							>
								Укажите прежние ФИО
							</BaseMessage>
						</>
					)}
				</div>
			</div>
			{nameChanged && (
				<>
					<div>
						<DadataInput
							type="surname"
							placeholder="Прежняя Фамилия *"
							defaultValue={prevSurname.value || prevSurname}
							sessionKey="prevSurname"
							value={(v) =>
								setPersonalForm({ ...personalForm, prevSurname: v.value || "" })
							}
							disabled={disabled}
							onlyLetters
						/>
					</div>
					<div>
						<DadataInput
							type="name"
							placeholder="Прежнее Имя *"
							defaultValue={prevName.value || prevName}
							sessionKey="prevName"
							value={(v) =>
								setPersonalForm({ ...personalForm, prevName: v.value || "" })
							}
							disabled={disabled}
							onlyLetters
						/>
					</div>
					<div>
						<DadataInput
							type="patronymic"
							placeholder="Прежнее Отчество *"
							defaultValue={prevPatronymic.value || prevPatronymic}
							sessionKey="prevPatronymic"
							value={(v) =>
								setPersonalForm({
									...personalForm,
									prevPatronymic: v.value || "",
								})
							}
							disabled={disabled}
							onlyLetters
						/>
					</div>
				</>
			)}
			<div>
				<NativeSelect
					placeholder="Пол *"
					options={genderTemplate}
					defaultValue={gender}
					sessionKey="gender"
					onSearch={null}
					onChanged={(v) =>
						setPersonalForm({ ...personalForm, gender: v || "" })
					}
					disabled={disabled}
				/>
			</div>
			<div>
				<DateInput
					placeholder="Дата рождения *"
					sessionKey="birthDate"
					defaultValue={birthDate}
					value={(v) =>
						setPersonalForm({ ...personalForm, birthDate: v || "" })
					}
					disabled={disabled}
				/>
			</div>
			<div>
				<DadataInput
					type="city"
					placeholder="Город рождения *"
					defaultValue={birthPlace?.value || birthPlace}
					sessionKey="birthPlace"
					value={(v) =>
						setPersonalForm({ ...personalForm, birthPlace: v.value || "" })
					}
					disabled={disabled}
					onlyLetters
				/>
			</div>
			<div>
				<BaseInput
					placeholder="СНИЛС"
					type={"snils"}
					defaultValue={snils || ""}
					sessionKey="snils"
					value={(v) => setPersonalForm({ ...personalForm, snils: v || "" })}
					disabled={disabled}
				/>
			</div>
		</form>
	);
};
export default PersonalForm;
